import {androiddownurl2, iosdownurl1, iosdownurl2} from "@/util/common";

export default {
    data() {
        return {
            usertypeOptions:[
                {
                    label:'商家',
                    value:2
                },
                {
                    label:'客服',
                    value:3
                },
            ],
            ordertypeOption:[
                {
                    label:'市場訂單',
                    value:1
                },
                {
                    label:'充值訂單',
                    value:2
                },
                {
                    label:'下發訂單',
                    value:3
                },{
                    label:'商户提現訂單',
                    value:4
                },{
                    label:'商户轉賬訂單',
                    value:5
                },{
                    label:'系统發行訂單',
                    value:99
                }
            ],
            stateOption:[
                {
                    label:'已创建',
                    value:1
                },
                {
                    label:'已確認',
                    value:2
                },
                {
                    label:'已支付',
                    value:3
                },{
                    label:'已轉幣',
                    value:4
                },
                {
                    label:'已提交',
                    value:5
                },
                {
                    label:'已審核',
                    value:6
                },{
                    label:'取消中',
                    value:7
                },{
                    label:'已取消',
                    value:8
                }
            ]
        }
    },
    methods: {
        ios1(){
            window.location.href=iosdownurl1
        },
        ios2(){
            window.location.href=iosdownurl2
        },
        android1(){
            window.location.href='./dowload/gopay.apk'
        },
        android2(){
            window.location.href=androiddownurl2
        }
    }
}