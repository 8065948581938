<template>
  <div class="header">
    <div class="header-container">
      <ul class="nav">
        <li>
          <a href="/" class="nav-tab">首页<span></span></a>
        </li>
        <!-- <li class="nav-tab" @click="toproduct">
          <a href="toproduct">产品介绍+<span></span> </a>
        </li> -->
        <li class="nav-tab">
          <a href="/###" @click="gotoCooperation">商务合作+<span></span> </a>
        </li>
      </ul>
      <div class="header-content">
        <div>
          <div>
            <!-- <p class="header-left-title">GOPay钱包</p> -->
            <img
              class="logo-img"
              src="../../assets/image/mobile/GOpay-logo.png"
              alt=""
            />
            <div class="header-left-title-desc">1分钟飙速加值，畅行游戏</div>
          </div>
          <div class="header-download">
            <div class="header-download-left">
              <div class="header-download-left-line1">ios下载地址：</div>
              <div class="header-download-left-line2">
                <button class="header-download-left-line2-ios" @click="ios1">
                  <img src="../../assets/image/ios1.png" />
                  <span>iOS下载</span>
                </button>
              </div>
              <div class="header-download-left-line1">Android下载地址：</div>
              <div class="header-download-left-line2">
                <button
                  class="header-download-left-line2-android"
                  @click="android1"
                >
                  <img src="../../assets/image/android1.png" />
                  <span>Android下载</span>
                </button>
              </div>
            </div>
            <div class="header-barcode">
              <img src="../../assets/image/qrcode.jpg" />
              <span>扫码下载包</span>
            </div>
          </div>
        </div>
        <img src="../../assets/image/phone.png" style="margin-left: 229px" />
      </div>
      <div class="to-tutorials" v-show="!showBtn">
        <img @click="gotoRecharge" class="to-tutorials-img" src="../../assets/image/to-tutorials.png" alt="">
        <img @click="showbtn()" class="to-tutorials-close" src="../../assets/image/close_icon.png" alt="">
      </div>
      <div class="header-footer">
        <button class="button" @click="gotoRecharge">gopay教程</button>
        <button class="button" @click="gotoRecharge">加值教程</button>
        <button class="button" @click="gotoPickup">提领教程</button>
        <button class="button" @click="gotoBuymoney">买币教程</button>
        <button class="button" @click="gotoSellmoney">卖币教程</button>
      </div>
    </div>
  </div>
</template>

<script>
import mix from "../../util/mix";
export default {
  name: "head1",
  data() {
    return {
      show1: false,
      show2: false,
      showBtn:false
    };
  },
  mixins: [mix],
  methods: {
    // gotutorials(){
    //   this.$router.push({
    //     path: "/tutorials",
    //   })
    // },
    showbtn(){
      this.showBtn = !this.showBtn
    },
    toproduct() {
      var target = document.querySelector(".main-container3");
      target.scrollIntoView(true);
    },
    gotoSellmoney() {
      this.$router.push({
        path: "/tutorials",
        query: {
          type: "sellmoney",
        },
      });
    },
    gotoPickup() {
      this.$router.push({
        path: "/tutorials",
        query: {
          type: "pickup",
        },
      });
    },
    gotoBuymoney() {
      this.$router.push({
        path: "/tutorials",
        query: {
          type: "buymoney",
        },
      });
    },
    gotoRecharge() {
      this.$router.push({
        path: "/tutorials",
        query: {
          type: "recharge",
        },
      });
    },
    visible() {
      this.show1 = true;
    },
    invisible() {
      this.show1 = false;
    },
    visible2() {
      this.show2 = true;
    },
    invisible2() {
      this.show2 = false;
    },
    gotoCooperation() {
      this.$router.push({
        path: "/cooperation",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  .header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 57.375rem;
    background-repeat: no-repeat;
    background-image: url("../../assets/image/head-bg.png");
    background-size: cover;
    width: 100%;
    .nav {
      font-family: MicrosoftHaHei;
      font-weight: normal;
      list-style: none;
      margin-top: 10px;
      li {
        z-index: 9999;
        float: left;
        margin: 0 30px;
        a {
          text-decoration: none;
          color: #ebd8b7;
          text-align: center;
          display: block;
          transition: transform 0.5s;
          position: relative;
          a:hover {
            transform: scale(1.2, 1.2);
          }
          span {
            height: 6px;
            background: #ffffff;
            display: block;
            border-radius: 3px;
            margin-top: 10px;
            transition: transform 0.5s;
            transform: scaleX(0);
            transform-origin: 100% 0;
          }
          a:hover span {
            transform: scaleX(1);
            transform-origin: 0 0;
          }
        }
      }
    }
    .menu-list {
      width: 100%;
      display: block;
      position: absolute;
      right: 0;
      background-color: ghostwhite;
      top: 30px;
      div:hover {
        color: #2c3e50;
        background-color: cadetblue;
      }
      div {
        padding: 5px 0;
        color: orangered;
      }
    }
  }
  .header-content {
    position: relative;
    top: 3.125rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .header-left-title {
      height: 3.6875rem;
      font-size: 4.1875rem;
      font-family: MicrosoftHaHei;
      font-weight: 400;
      color: #fff;
      line-height: 4.5rem;
      margin-bottom: 1.25rem;
    }
    .header-left-title-desc {
      width: 21.0625rem;
      height: 1.5625rem;
      font-size: 1.5625rem;
      font-family: MicrosoftHaHei;
      font-weight: 600;
      color: #fff;
      line-height: 2.25rem;
    }
  }
  .to-tutorials {
    position: fixed;
    bottom: 20%;
    right: -28rem;
    z-index: 99;
  .to-tutorials-img{
    width: 30%;
    cursor: pointer;
  }

  .to-tutorials-close {
    cursor: pointer;
    width: 5%;
    position: absolute;
    top: 10px;
    left: 135px;
  }
}
  .header-download {
    margin-top: 2.9375rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 31.25rem;
    .header-download-left {
      display: flex;
      flex-direction: column;
      .header-download-left-line1 {
        height: 2.5rem;
        line-height: 2rem;
        background-color: rgb(62, 63, 66);
        text-align: left;
        font-size: 16pt;
        padding-left: 0.5625rem;
        padding-top: 0.5rem;
        font-size: 12pt;
        font-family: MicrosoftHaHei;
        color: rgb(232, 210, 174);
      }
      .header-download-left-line2 {
        display: flex;
        justify-content: space-around;
        margin-top: 0.875rem;
        margin-bottom: 1.25rem;
        flex-wrap: wrap;
        .header-download-left-line2-ios {
          color: #4f4736;
          text-align: center;
          text-decoration: none;
          font-size: 14pt;
          cursor: pointer;
          height: 2.5rem;
          background: #ffd382;
          border-radius: 2.9375rem;
          width: 18rem;
          border: none;
          span {
            font-size: 16px;
            font-family: MicrosoftHaHei;
            font-weight: 400;
            color: #4f4736;
            line-height: 0.875rem;
            position: relative;
            top: -0.3rem;
            left: 0.4375rem;
          }
          img {
            height: 1.8rem;
            position: relative;
            left: -0.1875rem;
          }
        }
        .header-download-left-line2-android {
          color: #4f4736;
          text-align: center;
          cursor: pointer;
          height: 2.5rem;
          background: #ffd382;
          border-radius: 2.9375rem;
          width: 18rem;
          border: none;
          img {
            height: 1.8rem;
            position: relative;
            left: -0.1875rem;
          }
          span {
            font-size: 16px;
            font-family: MicrosoftHaHei;
            color: #4f4736;
            position: relative;
            top: -0.3rem;
            left: 0.4375rem;
          }
        }
      }
      .header-download-left-line3 {
        display: flex;
        justify-content: flex-start;
      }
    }
    .header-barcode {
      width: 10.125rem;
      height: 11.875rem;
      text-align: center;
      img {
        width: 10.125rem;
        height: 10.125rem;
      }
      span {
        width: 6.3125rem;
        height: 1rem;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #ebd8b7;
        line-height: 2.25rem;
      }
    }
  }
  .header-footer {
    position: relative;
    top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-items: center;
    width: 1203.8px;
    .button {
      border: none;
      color: #ffeecf;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 30px;
      margin: 4px 2px;
      cursor: pointer;
      width: 236px;
      height: 82px;
      background: rgb(62, 63, 66);
      box-shadow: 0px 13px 36px 2px rgba(233, 144, 64, 0.35);
      border-radius: 41px;

      font-family: MicrosoftHaHei;
      font-weight: 600;
    }
  }
}
</style>
