<template>
  <div class="home">
      <head1></head1>
      <main1></main1>
    <footer1></footer1>
  </div>
</template>

<script>
// @ is an alias to /src
import Head1 from "@/views/head";
import Main1 from "@/views/main";
import Footer1 from "@/views/footer";
export default {
  name: 'Home',
  components: {
    Footer1,
    Main1,
    Head1
  },
  created(){
    if(!this.$pc){
      this.$router.push({
        path:'/mobile'
      })
    }
  }
}
</script>
