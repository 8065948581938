<template>
<div class="footer">
  <div class="footer-container1">
    <div class="footer-container1-header">
      <span>一个账号 遨游全球</span>
      <span></span>
    </div>
    <div class="footer-container1-block">
      <div class="footer-container1-block-item1">
        <img src="../../assets/image/footer-image1.png">
        <span>跨境交易</span>
        <span>GOPay让您不受时间及国界限制，无论何时何地都能完成交易。</span>
      </div>
      <div class="footer-container1-block-item2">
        <img src="../../assets/image/footer-image2.png">
        <span>游戏网站加值</span>
        <span>您可以使用GOPay扫一扫游戏网站的二维码，加值一秒完成。</span>
      </div>
    </div>
  </div>
  <div class="footer-container2">
    <div class="footer-container2-content">
      <div class="footer-barcode">
        <img  src="../../assets/image/qrcode.jpg">
      </div>
      <div class="footer-container2-right">
        <span class="footer-container2-right-title">获得您的GOPay钱包</span>
        <div class="footer-container2-right-line1">ios下载地址：</div>
        <div class="footer-container2-right-line2" style="width: 252px;justify-content: space-between">
          <button class="footer-container2-right-line2-ios" @click="ios1">
            <img src="../../assets/image/ios1.png">
            <span>iOS下载</span>
          </button>
        </div>
        <div class="footer-container2-right-line1">Android下载地址：</div>
        <div class="footer-container2-right-line2">
          <button class="footer-container2-right-line2-android" @click="android1">
            <img src="../../assets/image/android1.png">
            <span>Android下载</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-container3">
    <span class="footer-container3-line1">隐私权政策 / 服务条款</span>
    <span>版权所有 ©2023 金年会 保留所有权</span>
  </div>
</div>
</template>

<script>
import mix from "@/util/mix";
export default {
  name:'footer1',
  mixins:[mix],
  methods:{
  }
}
</script>

<style lang="less" scoped>
.footer{
  display: -webkit-flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .footer-container1{
    background-size: cover;
    width: 100%;
    height: 548px;
    background-repeat: no-repeat;
    background-image: url("../../assets/image/bg-footer.png");
    display: -webkit-flex;
    flex-direction: column;
    align-items: center;
    .footer-container1-header{
      display: flex;
      flex-direction: column;
      align-items: center;
      span:first-child{
        font-size: 48px;
        font-family: MicrosoftYaHei-Bold;
        font-weight: 600;
        color: #ffd382;
        padding-top: 68px;
      }
      span:last-child{
        margin-top: 18px;
        width: 390px;
        height: 2px;
        background: #FEB067;
      }
    }
    .footer-container1-block{
      display: flex;
      justify-content: space-between;
      width: 64%;
      padding-top: 87px;
      .footer-container1-block-item1{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span:nth-child(2){
          font-size: 24px;
          font-family: MicrosoftYaHei-Bold;
          font-weight: 600;
          color: #ffd382;
          padding-top: 43px;
        }
        span:last-child{
          font-size: 19px;
          font-family: Source Han Sans SC VF;
          font-weight: 400;
          color: #ffeecf;
          padding-top: 29px;
        }
      }
      .footer-container1-block-item2{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span:nth-child(2){
          font-size: 25px;
          font-family: Source Han Sans SC VF;
          font-weight: 600;
          color: #ffd382;
          padding-top: 43px;
        }
        span:last-child{
          font-size: 19px;
          font-family: Source Han Sans SC VF;
          font-weight: 400;
          color: #ffeecf;
          padding-top: 29px;
        }
      }
    }
  }
  .footer-container2{
    background-repeat: no-repeat;
    background-image: url("../../assets/image/pc-download-bg-1.png") ;
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    // height: 333px;
    .footer-container2-content {
      width: 52%;
      margin: 0 24%;
      display: flex;
      justify-content: center;
      align-items: center;
      .footer-barcode{
      width: 166px;
      height: 166px;
      // background: #F0F0F0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 15px;
    img{
      width: 140px;
      height: 140px;
    }
    }
    .footer-container2-right{
      margin: 0 15px;
      display: flex;
      flex-direction: column;
      // width: 417px;
      .footer-container2-right-title{
        font-size: 28px;
        font-family: MicrosoftYaHei-Bold;
        font-weight: 600;
        color: #ffd382;
        padding-top: 20px;
        align-self: start;
        margin-bottom: 18px;
      }
      .footer-container2-right-line1{
        background-repeat: no-repeat;
        background-image: url("../../assets/image/pc-download-bg-1.png") ;
        background-size: 100%;
        height: 28px;
        line-height: 28px;
        // background-color: rgba(240,240,240,0.6);
        // text-align: left;
        color: #e8d2ae;
        font-size: 16px;
        padding-left: 9px;
        padding-top: 8px;
      }
      .footer-container2-right-line2{
        justify-content: space-around;
        margin-top: 14px;
        margin-bottom: 20px;
        flex-wrap: wrap;
        .footer-container2-right-line2-ios{
          height: 2.5rem;
          width: 15rem;
          border: none;
          text-align: center;
          font-size: 16px;
          cursor: pointer;
          background: #ffd382;
          border-radius: 14px;
          span{
            font-size: 16px;
            font-weight: 400;
            font-family: MicrosoftHaHei;
            font-weight: 400;
            color: #4f4736;
            line-height: 14px;
            position: relative;
            left: 7px;
            bottom: 0px;
          }
          img{
            height: 18px;
          }
        }
        .footer-container2-right-line2-android{
          height: 2.5rem;
          width: 15rem;
          border: none;
          text-align: center;
          font-size: 16px;
          cursor: pointer;
          background: #ffd382;
          border-radius: 14px;
          span{
            font-size: 16px;
            font-family: SourceHanSansSCVF;
            font-weight: 400;
            color: #4f4736;
            line-height: 14px;
            height: 14px;
            position: relative;
            left: 4px;
            bottom: 1px;
          }
          img{
            width: 16px;
          }
        }
      }
     }  
    }
  }
  .footer-container3{
    background-repeat: no-repeat;
    background-image: url("../../assets/image/bg-footer.png") ;
    background-size: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    // background-color: black;
    padding: 30px 0;
    span{
      color: #e8d2ae;
      font-size: 16px;
    }
  }
  }
</style>