<template>
<div class="main">
  <div class="main-container1">
    <div class="main-container1-block">
      <div class="main-container1-block-item1">
          <img src="../../assets/image/main-container1-block-item1.png">
          <span>24/7交易无国界</span>
          <span></span>
      </div>
      <div class="main-container1-block-item2">
          <img src="../../assets/image/main-container1-block-item2.png">
          <span>支援主流加密货币</span>
          <span></span>
      </div>
    </div>
  </div>
  <div class="main-container2">
    <div class="main-container2-block">
      <div class="main-container2-block-item1">
<!--        <div class="main-container2-block-item1-img"></div>-->
      </div>
      <div class="main-container2-block-item2">
        <span>GOP币</span>
        <div style="margin-left: -22px"><img src="../../assets/image/copy.png"><span>汇率稳定性高，恒定人民币币值</span></div>
        <div><img src="../../assets/image/copy.png"> <span>横跨多平台及GOPay签约商户使用</span></div>
      </div>
    </div>
  </div>
  <div class="main-container3">
    <div class="main-container3-block1">
      <span>GOPay钱包功能</span>
      <span></span>
    </div>
    <div class="main-container3-block2">
      <div class="main-container3-block2-item1">
        <span>存取GOP币</span>
        <span></span>
        <span>透过GOPay钱包，您可以轻松简单发送及接收GOP币。</span>
      </div>
      <div class="main-container3-block2-item2">
        <img src="../../assets/image/tu1.png">
      </div>
    </div>
    <div class="main-container3-block3">
      <div class="main-container3-block3-item1">
        <img src="../../assets/image/tu2.png">
      </div>
      <div class="main-container3-block3-item2">
        <span>买卖GOP币</span>
        <span></span>
        <span>GOPay钱包提供P2P交易服务，挂单、付款、打币；买卖只要三 步骤即可完成。</span>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name:'main1'
}
</script>

<style lang="less" scoped>
.main{
  .main-container1{
    // width: 1920px;
    // height: 526px;
    width: 120rem;
    height: 32.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .main-container1-block{
      display: flex;
      justify-content: space-between;
      width: 951.8px;
      height: 374px;
      .main-container1-block-item1{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 411px;
        height: 374px;
        background: #FFFFFF;
        box-shadow: 0px 0px 30px 8px rgba(92, 92, 92, 0.06);
        border-radius: 10px;
        span{
          margin-top: 30px;
          font-size: 25px;
          font-family: SourceHanSansCN;
          font-weight: 600;
          color: #454545;
        }
        :last-child{
          width: 97px;
          height: 3px;
          background: #FFC066;
        }
      }
      .main-container1-block-item2{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 411px;
        height: 374px;
        background: #FFFFFF;
        box-shadow: 0px 0px 30px 8px rgba(92, 92, 92, 0.06);
        border-radius: 10px;
        span{
          margin-top: 30px;
          font-size: 25px;
          font-family: SourceHanSansCN;
          font-weight: 600;
          color: #454545;
        }
        :last-child{
          width: 97px;
          height: 3px;
          background: #FFC066;
        }
      }
    }
   }
   .main-container2{
    height: 387px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    background-image: url("../../assets/image/BJ2.png");
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    .main-container2-block{
      display: -webkit-flex;
      justify-content: space-between;
      width: 951.8px;
      height: 374px;
      .main-container2-block-item1{
        width: 131px;
        height: 152px;
      }
      .main-container2-block-item2{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        span:first-child{
          font-size: 48px;
          font-family: MicrosoftHaHei-Bold;
          font-weight: 800;
          color: #3a3c3e;
        }
        div{
          margin-top: 43px;
          span{
            width: 298px;
            height: 21px;
            font-size: 25px;
            font-family: MicrosoftHaHei-Bold;
            color: #3a3c3e;
            font-weight: 600;
          }
          img{
            position: relative;
            top: 5px;
            right: 5px;
          }
        }
      }
    }
  }
  .main-container3{
    display: -webkit-flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 117px;
    .main-container3-block1{
      display: flex;
      flex-direction: column;
      align-items: center;
      span:first-child{
        padding-top: 80px;
        font-size: 48px;
        font-family: MicrosoftHaHei-Bold;
        font-weight: 600;
        color: #3a3c3e;
      }
      :last-child{
        width: 350px;
        height: 3px;
        background: #FFC066;
        margin-top: 10px;
      }
    }
    .main-container3-block2{
      display: flex;
      justify-content: space-between;
      width: 60%;
      padding-top: 40px;
      .main-container3-block2-item1{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        span:first-child{
          height: 28px;
          font-size: 29px;
          font-family: MicrosoftHaHei-Bold;
          font-weight: 600;
          color: #3a3c3e;
        }
        // span:nth-child(2){
        //   height: 3px;
        //   background: #F33573;
        //   width: 47px;
        //   margin-top: 15px;
        // }
        span:last-child{
          height: 28px;
          font-size: 24px;
          font-family: MicrosoftHaHei-Bold;
          font-weight: 400;
          color: #847c6d;
          margin-top: 26px;
        }
      }
    }
    .main-container3-block3{
      display: -webkit-flex;
      justify-content: space-between;
      width: 60%;
      padding-top: 40px;
      .main-container3-block3-item2{
        margin-left: 4rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        span:first-child{
          height: 28px;
          font-size: 29px;
          font-family: MicrosoftHaHei-Bold;
          font-weight: 600;
          color: #3a3c3e;
        }
        // span:nth-child(2){
        //   height: 3px;
        //   background: #F33573;
        //   width: 47px;
        //   margin-top: 15px;
        // }
        span:last-child{
          height: 28px;
          font-size: 24px;
          font-family: MicrosoftHaHei-Bold;
          font-weight: 400;
          color: #847c6d;
          margin-top: 26px;
        }
      }
    }
  }
}
</style>